/* Colors */
:root {
	--primary: black;
	--secondary: white;
	--accent: #c17df1;
	--link: #3e8ead;
	--link_hovered: #357994;
}

body {
	font-family: "Montserrat", serif;
	margin: 0px;
	background-color: var(--secondary);
	font-size: 100%;
}

img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}

a {
	color: var(--primary);
}

a:hover {
	color: rgb(73, 73, 73);
	text-decoration: none;
}

a.blue-link,
.blue-link a {
	color: var(--link);
}

a.blue-link:hover,
.blue-link a:hover {
	color: var(--link_hovered);
	text-decoration: underline;
}

hr {
	background-color: var(--primary);
	border: 1px solid var(--primary);
}

/* Nav Bar */
.navbar {
	font-family: "Coustard", serif;
	background-color: var(--accent);
	color: var(--primary);
	height: auto;
}
.nav-item a {
	color: var(--primary);
}
.navbar-brand a {
	color: var(--primary);
}
.navbar-brand h4 {
	margin: 0px;
	font-weight: bolder;
}
.navbar-text {
	font-size: 24px;
}
.navbar-toggler {
	border: none;
	color: var(--primary) !important;
}
.navbar-toggler:focus {
	outline: none;
}
.dropdown-menu {
	border-radius: 0%;
}

/* Footer */
.foot {
	background: var(--accent);
	color: var(--primary);
	margin-top: 40px;
	padding: 20px 0px;
}
.foot p {
	margin-bottom: 10px;
	text-align: center;
}
.foot h6 {
	font-family: "Coustard", serif;
	font-weight: bolder;
}

/* Icons */
.icon-link {
	padding: 0px 5px;
}
.icon-list a {
	color: var(--primary);
	font-size: 24px;
}

/* Carousels */
.carousel-content {
	background-color: rgb(51, 51, 51);
	height: 350px;
}
.carousel-content img {
	margin: auto;
	object-fit: contain;
	height: 350px;
}
.carousel {
	margin-bottom: 7%;
}
.carousel-indicators {
	bottom: auto;
	top: 102%;
}
.carousel-indicators li {
	background-color: rgb(185, 185, 185);
}
.carousel-indicators .active {
	background-color: #666;
}
.carousel-control-bg {
	background-color: rgba(0, 0, 0, 0.425);
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
}

/* Custom Buttons */
.custom-button {
	padding: 15px;
	background: var(--primary);
	color: var(--secondary);
	border: none;
	text-align: center;
	justify-content: center;
}
.custom-button:hover {
	background: rgb(26, 26, 26);
}
.custom-button p {
	margin: auto;
	font-weight: bold;
}

/* Home Page */
.splash {
	/* background-image: url("../images/splashbg.svg"); */
	padding: 20px;
	background: linear-gradient(0deg, var(--secondary), var(--accent));
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
}
.portrait {
	display: flex;
	align-items: center;
	justify-content: center;
}
.portrait img {
	/* margin: 50px; */
	margin-top: 30px;
	margin-bottom: 30px;
	object-fit: cover;
	border-radius: 50%;
	border: 3px solid var(--primary);
}
.portrait-box {
	padding: 10px 10px 0px 10px;
}
.portrait-name,
.portrait-description {
	text-align: center;
	color: var(--primary);
}
.portrait-name {
	font-family: "Coustard", serif;
	font-weight: bolder;
}

/* Game Preview */
.game-preview {
	width: 480px;
	height: 360px;
}

/* 404 */
.not-found h1,
.not-found p {
	margin: 10px 0px;
	text-align: center;
}

/* Misc */
.object-fit_cover {
	object-fit: cover;
}
.vertical-center {
	display: flex;
	align-items: center;
}
.main-div {
	min-height: calc(100vh - 266px);
}
.big-text {
	font-family: "Coustard", serif;
	font-weight: 500;
}
.pad0 {
	padding: 0px;
}
.aspect-ratio-16x9 {
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
}
.aspect-ratio-inner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.youtube-video {
	width: 100%;
	height: 100%;
}

/* Legacy Construction */
.construction-body {
	display: flex;
	align-items: center;
	justify-content: center;

	border-left: 10px solid var(--accent);
	border-right: 10px solid var(--accent);
	border-bottom: 10px solid var(--accent);
	background-color: var(--secondary);
}
.construction-body p {
	margin: 5px;
	padding: 20px;
	text-align: center;

	font-style: italic;
	font-size: 1.25em;
}
.construction-header {
	text-align: center;
	background-color: var(--accent);
}
.construction-header h2 {
	margin: 0px;
	padding: 10px;
}
